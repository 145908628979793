import { Component, EventEmitter, OnInit } from '@angular/core';
import { ADDRESSBOOK_TYPES, AddressbookRecord, CallService } from '../../../core/services';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../core/services/notifications';
import { AddEditContactModalComponent } from '../../../contacts/add-edit-contact-modal/add-edit-contact-modal.component';
import { RecordFullnamePipe } from '../../pipes';
import { UtilsService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import * as i18nIsoCountries from 'i18n-iso-countries';

enum ContactTypes {
  INTERNAL = 'internal',
  LOCAL = 'local',
  REMOTE = 'remote'
}

@Component({
  selector: 'contact-details-modal',
  templateUrl: './contact-details-modal.component.html',
  styleUrls: [
    '../../styles/contact-list/contact-list.scss',
    './contact-details-modal.component.scss'
  ]
})
export class ContactDetailsModalComponent implements OnInit {

  public contact: AddressbookRecord;
  public canUpdate: boolean = false;
  public isIntegration: boolean = false;

  public type: ContactTypes;                // used in template to change remote action from local action
  public contactTypes = ContactTypes;

  public onChangeContact: EventEmitter<AddressbookRecord> = new EventEmitter();
  public onEditRemote: EventEmitter<void> = new EventEmitter();
  public onEditLocal: EventEmitter<void> = new EventEmitter();
  public onDeleteRemote: EventEmitter<void> = new EventEmitter();
  public onDeleteLocal: EventEmitter<void> = new EventEmitter();
  public language: string = 'it';

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private recordFullname: RecordFullnamePipe,
    private callService: CallService,
    private utilService: UtilsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    switch(this.contact.type) {
      case ADDRESSBOOK_TYPES.INTERNAL_USER:
      case ADDRESSBOOK_TYPES.INTERNAL_GROUP:
        this.type = ContactTypes.INTERNAL;
        break;
      case ADDRESSBOOK_TYPES.LOCAL:
        this.type = ContactTypes.LOCAL;
        break;
      case ADDRESSBOOK_TYPES.EXTERNAL:
      case ADDRESSBOOK_TYPES.CRM:
        this.type = ContactTypes.REMOTE;
        break;
    }
    this.isIntegration = this.contact.CRM && this.contact.CRM !== 'Voxloud';
    this.language = this.translateService.currentLang;
    if(this.language) {
      i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${this.language}.json`));
    }
  }

  public onToggleFavorite() {
    this.contact.favorite = this.contact.favorite ? 0 : 1;
    this.onChangeContact.emit(this.contact);
  }

  public call(contact: AddressbookRecord, number: string) {
    this.callService.callFromRecord(contact, number);
    this.activeModal.close();
  }

  public editRemote() {
    this.onEditRemote.emit();
  }

  public editLocal() {
    this.onEditLocal.emit();
  }

  public deleteRemote() {
    this.notificationService.askConfirmation({
      messageToken: 'common.contact-details.confirmations.delete-one',
      messageParameters: {
        contactName: this.recordFullname.transform(this.contact)
      }
    }).then((response: string) => {
      if(response === 'ok') {
        this.onDeleteRemote.emit();
      }
    });
  }

  public deleteLocal() {
    this.notificationService.askConfirmation({
      messageToken: 'common.contact-details.confirmations.delete-one',
      messageParameters: {
        contactName: this.recordFullname.transform(this.contact)
      }
    }).then((response: string) => {
      if(response === 'ok') {
        this.onDeleteLocal.emit();
      }
    });
  }

  public close() {
    this.activeModal.close();
  }


  public openLink(link: string): void {
    this.utilService.openExternalLink(/^http(s)?:\/\//.test(link) ? link : `https://${link}`);
  }

  public mailTo(email: string): void {
    this.utilService.openExternalLink(`mailto://${email}`);
  }

  public getCountryFullName(code: string) {
    const countries = require('i18n-iso-countries')
    return countries.getName(code, this.language)
  }
}

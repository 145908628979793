import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatabaseService } from '../database';
import { AppConfig } from '../../../../environments/environment';
import {
  ADDRESSBOOK_TYPES,
  VCardTel,
  TEL_CAPABILITIES,
  VCardImportStatistics,
  CrmLookupFilters,
  CrmLookupResponse,
  CrmLookupPhoneFilter,
  InternalUserResponse,
  InternalGroupResponse,
  AddressbookRecord,
  AddressbookResponse,
  APIAddressbookRecord,
  AddressbookRecordPhoneLabel,
  PaginatedAPIAddressbookContacts,
  AddressbookRecordEmailLabel,
  APIAddressbookPermission,
  APIAddressbookFilter,
  ImportResponse,
  VCardEmail,
  EMAIL_CAPABILITIES
} from './addressbook.model';
import * as vCard from 'vcf';
import MD5 from 'crypto-js/md5';
import Hex from 'crypto-js/enc-hex';
import { Observable, Subject } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { AccountFacade } from '../../../account/store/facade';
import { Logger, LoggerService } from '../logger';
import { Addon, SessionDTO, SubscriptionLite } from '../../../account/store/states-models';


@Injectable({ providedIn: 'root' })
export class AddressbookService {

  private logger: Logger;
  public externalContactImported$: Subject<void> = new Subject<void>();
  public newContactAdded$: Subject<void> = new Subject<void>();

  public favoriteChange$: Subject<AddressbookRecord> = new Subject<AddressbookRecord>();

  constructor(
    private dbService: DatabaseService,
    private httpClient: HttpClient,
    private accountFacade: AccountFacade,
    private loggerService: LoggerService) {
    this.logger = this.loggerService.getLoggerInstance('AddressbookService');
  }

  /**
   * If the app is online get the internal addressbook from the backend and save 
   */
  public getAddressbook(pbxId: string): Observable<string[]> {
    // If the app is online, get the addressbook
    // Otherwise show the old one (just for reference anyway)
    return this.httpClient.get<AddressbookResponse>(`${AppConfig.endpointURL}/v1/pbxes/${pbxId}/address-book`).pipe(concatMap((res) => {
      let addressbook$ = this.dbService.getAllAddressbookRecords().pipe(concatMap((addressbook) => {
        const users = this.mapInternalUserRecords(res.users, addressbook);
        const groups = this.mapInternalGroupRecords(res.groups, addressbook);
        this.purgeMissing(addressbook, [...res.users, ...res.groups]);
        return this.dbService.addOrUpdateAddressbookRecord([...users, ...groups]);
      }));
      return addressbook$;
    }));
  }

  /**
   * Remove from database these values that are not present in the data from backend (deleted users and groups)
   * @param {AddressbookRecord[]} local Local array got from database
   * @param {InternalUserResponse | InternalGroupResponse} remote Array of groups and users from backend
   */
  private purgeMissing(local: AddressbookRecord[], remote: (InternalUserResponse | InternalGroupResponse)[]) {
    local.forEach((el: AddressbookRecord) => {
      if(el.type && el.type === ADDRESSBOOK_TYPES.INTERNAL_USER || el.type === ADDRESSBOOK_TYPES.INTERNAL_GROUP) {
        const id = el.remoteId.substring(1);
        const find = remote.find(rem => {
          if(rem['group_id']) {
            return (rem as InternalGroupResponse).group_id === id;
          } else {
            return (rem as InternalUserResponse).user_id === id;
          }
        });
        if(!find){
          this.dbService.deleteAddressbookRecord(el.id);
        }
      }
    })
  }

  /**
   * Ask electron to open a file dialog and read a file. Parse the resulting string and add to the database
   */
  public getAddressbookByVCard(file: File): Observable<VCardImportStatistics> {
    const ret = new Subject<VCardImportStatistics>();
    this.readFileAsync(file).then(async (data: string) => {
      let importStats: VCardImportStatistics = {
        total: 0,
        skipped: 0,
        added: 0,
        errored: 0
      }
      // if file is not read or the dialog is cancelled an empty string or undefined should return
      if (!data) { ret.next(null); ret.complete(); return };
      let cards;
      try {
        cards = vCard.parse(data);
      } catch(error) {
        this.logger.error(error);
        throw error;
      }
      let results: AddressbookRecord[] = [];
      this.logger.debug('Start parsing vcards');
      for (let i = 0; i < cards.length; ++i) {
        const card = cards[i];
        importStats.total++;
        const jCardString = JSON.stringify(card.toJSON());
        const hash = MD5(jCardString).toString(Hex);
        const isPresent = await this.dbService.getAddressbookByHash(hash).toPromise();
        if (isPresent) {
          importStats.skipped++
          continue;
        }
        const numbers: VCardTel[] = this.extractVcardNumbers(card, importStats);
        const emails = this.extractVcardEmails(card, importStats);
        let record: AddressbookRecord = {
          type: ADDRESSBOOK_TYPES.LOCAL,
          firstName: this.extractVcardName(card),
          defaultNumber: {
            number: numbers[0]?.number,
            label: <AddressbookRecordPhoneLabel>(numbers[0]?.label),
          },
          numbers: this.mapExternalContactsNumbers(numbers),
          emails: this.mapExternalContactsEmails(emails),
          // The reason why the string is reversed is explained in the dbservice
          // This field is used only for indexing the db by numbers
          indexableTelNumbers: numbers.map(t => t.number.split('').reverse().join('')),
          hash: hash,
          favorite: 0
        };
        importStats.added++;
        results.push(record);
      }
      this.dbService.addMultipleAddressbookRecords(results);
      this.externalContactImported$.next();
      ret.next(importStats);
      ret.complete();
    }).catch((error) => {
      ret.error(error);
    });
    return ret;
  }

  /**
   * Asynchronous read the content of the file.
   * @param {File} file File with content to read;
   * @returns {string} The content of the file
   */
  private readFileAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        resolve(String(fileReader.result));
      };
      fileReader.readAsText(file);
      fileReader.onerror = (error) => {
        reject(error);
      }
    });
  }

  /**
   * Parse and format the name from the vCard
   * @param {vCard} card vCard record from which extract the name
   * @returns {string} The parsed name
   */
  private extractVcardName(card: vCard): string {
    // ValueOf should return already a string but seems like it's type is object
    const fn = card.get('fn')?.valueOf();
    let n = card.get('n')?.valueOf().toString();
    if(!fn && !n) return "";
    if (fn) return fn.toString();
    let r = n.split(';');
    // n struct -> surname; given name; additional name; honorifics prefix; honorifix suffix
    return `${r[3]} ${r[1]} ${r[0]}`;
  };

  /**
   * Parse and format the number struct from the vCard
   * @param {vCard} card vCard record from which extract the tel number struct
   * @returns {VCardTelStruct[]} An array of all the number structs extratted from this property
   */
  private extractVcardNumbers(card: vCard, stats: VCardImportStatistics): VCardTel[] {
    const tel: vCard.Property | vCard.Property[] = card.get('tel');
    let results: VCardTel[] = [];
    if (Array.isArray(tel)) {
      results = tel.map(t => this.extractVcardNumberFromProperty(t, card.get('xAbLabel')));
    } else {
      results.push(this.extractVcardNumberFromProperty(tel, card.get('xAbLabel')));
    }
    return results.filter(r => {
      if(r.label === 'Error') {
        stats.errored++;
        return false;
      }
      return true;
    });
  }

  /**
   * Helper method to extract the number struct from a single vcard property
   * @param {vCard.Property} property The tel property from which the number struct must be extracted
   * @param {vCard.Property} abLabels The optional labels for the numbers (X-AbLabel)
   * @returns {VCardTelStruct} The number struct
   */
  private extractVcardNumberFromProperty(property: vCard.Property, abLabels?: vCard.Property | vCard.Property[]): VCardTel {
    if(!property) {
      return {
        label: 'Error',
        number: undefined
      }
    }
    let struct: VCardTel = {
      // If value = uri then format -> tel:3333333. split in : and get second
      number: property['value'] === 'uri' ? property.valueOf().split(':')[1].replace(/[^0-9\+]+/g, '') : property.valueOf().replace(/[^0-9\+]+/g, ''),
      label: AddressbookRecordPhoneLabel.OTHER
    };
    if (property['type']) {
      if (Array.isArray(property['type'])) {
        struct.capability = [];
        property['type'].forEach(t => {
          if (TEL_CAPABILITIES.includes(t)) {
            struct.capability.push(t)
          } else {
            struct.type = t;
            struct.label = this.getNumberLabel(t);
          }
        });
      } else {
        struct.type = property['type'];
        struct.label = this.getNumberLabel(property['type'])
      }
    } else if (property['group']) {
      struct.group = property['group'];
      struct.label = this.findGroupInProperty(abLabels, struct.group);
    }
    return struct;
  }

   /**
   * Parse and format the email struct from the vCard
   * @param {vCard} card vCard record from which extract the tel email struct
   * @returns {VCardTelStruct[]} An array of all the email structs extratted from this property
   */
   private extractVcardEmails(card: vCard, stats: VCardImportStatistics) {
    const email: vCard.Property | vCard.Property[] = card.get('email');
    let results: VCardEmail[] = [];
    if (Array.isArray(email)) {
      results = email.map(t => this.extractVcardEmailFromProperty(t, card.get('xAbLabel')));
    } else {
      results.push(this.extractVcardEmailFromProperty(email, card.get('xAbLabel')));
    }
    return results.filter(r => {
      if(r.label === 'Error') {
        stats.errored++;
        return false;
      }
      return true;
    });
  }

  /**
   * Helper method to extract the email struct from a single vcard property
   * @param {vCard.Property} property The tel property from which the email struct must be extracted
   * @param {vCard.Property} abLabels The optional labels for the emails (X-AbLabel)
   * @returns {VCardEmailStruct} The email struct
   */
  private extractVcardEmailFromProperty(property: vCard.Property, abLabels?: vCard.Property | vCard.Property[]): VCardEmail {
    if(!property) {
      return {
        label: 'Error',
        email: undefined
      }
    }
    let struct: VCardEmail = {
      email: property.valueOf(),
      label: AddressbookRecordEmailLabel.OTHER
    };
    if (property['type']) {
      if (Array.isArray(property['type'])) {
        struct.capability = [];
        property['type'].forEach(t => {
          if (EMAIL_CAPABILITIES.includes(t)) {
            struct.capability.push(t)
          } else {
            struct.type = t;
            struct.label = this.getEmailLabel(t);
          }
        });
      } else {
        struct.type = property['type'];
        struct.label = this.getEmailLabel(property['type'])
      }
    } else if (property['group']) {
      struct.group = property['group'];
      struct.label = this.findGroupInProperty(abLabels, struct.group);
    }
    return struct;
  }

  /**
   * Get the label for a particular group name
   * @param {vCard.Property} property The labels for the numbers (X-AbLabel)
   * @param {string} group Group name
   * @returns {string} The label found
   */
  private findGroupInProperty(property: vCard.Property | vCard.Property[], group: string): string {
    if (!property) return '';
    if (Array.isArray(property)) {
      for (let i = 0; i < property.length; ++i) {
        if (property[i]['group'] && property[i]['group'] === group) return this.getNumberLabel(property[i].valueOf())
      }
    } else {
      if (property['group'] && property['group'] === group) return this.getNumberLabel(property.valueOf())
    }
  }

  // Helper method to assign correctly number labels in VCard
  private getNumberLabel(value): AddressbookRecordPhoneLabel {
    let label: AddressbookRecordPhoneLabel = AddressbookRecordPhoneLabel.OTHER;
    let numberLabels = Object.values(AddressbookRecordPhoneLabel);
    if(numberLabels.includes(value.toUpperCase())) {
      label = numberLabels.find(label => label === value.toUpperCase());
    } else {
      label = this.mapNumberLabel(value.toUpperCase());
    }
    return label;
  }

  private mapNumberLabel(value): AddressbookRecordPhoneLabel {
    switch(value) {
      case 'CELL': {
        return AddressbookRecordPhoneLabel.MOBILE;
      }
      default: 
      return AddressbookRecordPhoneLabel.OTHER;
    }
  }

  
  // Helper method to assign correctly email labels in VCard
  private getEmailLabel(value): AddressbookRecordEmailLabel {
    let label: AddressbookRecordEmailLabel = AddressbookRecordEmailLabel.OTHER;
    let emailLabels = Object.values(AddressbookRecordEmailLabel);
    if(emailLabels.includes(value.toUpperCase())) {
      label = emailLabels.find(label => label === value.toUpperCase());
    }
    return label;
  }

  /**
   * Search contacts in Crms based on the filters
   * @param {CrmLookupFilters} filters Filters to use
   */
  public searchInCrms(filters: CrmLookupFilters): Observable<CrmLookupResponse[]> {
    return this.accountFacade.session$.pipe(take(1), concatMap(
      (session: SessionDTO) => {
        // TODO: When the new api is ready (with devapi.voverc.com), change this endpoint
        return this.httpClient.post<CrmLookupResponse[]>(`${AppConfig.endpointURL}/v1/blendr/accounts/${session.company_id}/blends/Lookup caller/run`, {
          inputs: filters
        });
      }
    ));
  }

  /**
   * Used on incoming call to match an incoming number to a crm contact
   * @param {string} companyId Id of the company
   */
  public searchInCrmsByPhone(filter: CrmLookupPhoneFilter): Observable<CrmLookupResponse[]> {
    return this.accountFacade.session$.pipe(take(1), concatMap(
      (session: SessionDTO) => {
        return this.httpClient.post<CrmLookupResponse[]>(`${AppConfig.endpointURL}/v1/blendr/accounts/${session.company_id}/blends/Lookup caller by phone/run`, {
          inputs: filter
        });
      }
    ));
  }

  public sortRecordsByType(records: AddressbookRecord[]): AddressbookRecord[] {
    const internals: AddressbookRecord[] = records.filter(el => 
      el.type === ADDRESSBOOK_TYPES.INTERNAL_USER || el.type === ADDRESSBOOK_TYPES.INTERNAL_GROUP);
    const locals: AddressbookRecord[] = records.filter(el => 
        el.type === ADDRESSBOOK_TYPES.LOCAL);
    const externals: AddressbookRecord[] = records.filter(el =>
      el.type === ADDRESSBOOK_TYPES.EXTERNAL);
    return [...internals, ... locals, ... externals];
  }

  public addNewContact(contact: AddressbookRecord): void {
    this.dbService.addAddressbookRecord(contact);
    this.newContactAdded$.next();
  }

  // Helper methods to map different types of record
  private mapInternalUserRecords(users: InternalUserResponse[], addressbook: AddressbookRecord[]) {
    return users.map((record) => {
      const remoteId = `u${record.user_id}`;
      const userIndex = addressbook.findIndex((el: AddressbookRecord) => el.remoteId === remoteId);
      const user = userIndex !== -1 ? addressbook[userIndex] : undefined;
      const value: AddressbookRecord = {
        remoteId: remoteId,
        type: ADDRESSBOOK_TYPES.INTERNAL_USER,
        firstName: record.firstName,
        lastName: record.lastName,
        defaultNumber: {
          number: record.extension,
          label: AddressbookRecordPhoneLabel.PRIMARY
        },
        numbers: [{
          number: record.extension,
          label: AddressbookRecordPhoneLabel.OTHER
        }],
        favorite: 0,
        presenceId: record.presence_id,
        emails: [{
          email: record.email,
          label: AddressbookRecordEmailLabel.OTHER
        }],
        notes: record.note
      }
      if(user) {
        value.id = user.id;
        value.favorite = user.favorite;
      }
      return value;
    });
  }

  private mapInternalGroupRecords(groups: InternalGroupResponse[], addressbook: AddressbookRecord[]) {
    return groups.map((record) => {
      const remoteId = `g${record.group_id}`;
      const groupIndex = addressbook.findIndex((el: AddressbookRecord) => el.remoteId === remoteId);
      const group = groupIndex !== -1 ? addressbook[groupIndex] : undefined;
      const value: AddressbookRecord = {
        remoteId: remoteId,
        type: ADDRESSBOOK_TYPES.INTERNAL_GROUP,
        firstName: record.name,
        lastName: null,
        defaultNumber: {
          number: record.extension,
          label: AddressbookRecordPhoneLabel.PRIMARY
        },
        numbers: [{
          number: record.extension,
          label: AddressbookRecordPhoneLabel.OTHER
        }],
        groupType: record.group_type,
        favorite: 0,
        notes: record.description
      }
      if(group) {
        value.id = group.id;
        value.favorite = group.favorite
      }
      return value;
    });
  }

  public mapCRMContacts(contacts: CrmLookupResponse[]): AddressbookRecord[] {
    return contacts.map((el: CrmLookupResponse) => (
      {
        type: ADDRESSBOOK_TYPES.CRM,
        firstName: el['Contact Name'],
        defaultNumber: this.mapCRMContactsNumbers(el.Number)[0],
        numbers: this.mapCRMContactsNumbers(el.Number),
        icon: el.CRM_Icon,
        CRM: el.CRM,
        link: el['Crm Link']
      }
    ))
  }

  private mapCRMContactsNumbers(numbers) {
    return numbers.map(el => ({number: el.E164}))
  }

  private mapExternalContactsNumbers(numbers) {
    return numbers.map(el => ({label: el.label, number: el.number}))
  }

  private mapExternalContactsEmails(emails) {
    return emails.map(el => ({label: el.label, email: el.email}))
  }

  // API

  public hasSharedPhonebook(): Observable<boolean> {
    return this.accountFacade.subscription$.pipe(take(1), map((subscription: SubscriptionLite) => {
      return subscription.addons.some((a: Addon) => a.id.includes('address_book'));
    }));
  }

  public hasSharedPhonebookOrCrm(): Observable<boolean> {
    return this.accountFacade.subscription$.pipe(take(1), map((subscription: SubscriptionLite) => {
      return subscription.addons.some((a: Addon) => a.id.includes('address_book')) || subscription.addons.some((a: Addon) => a.id.includes('crm_integrations'));
    }));
  }

  public getContacts(companyId: number, filters: APIAddressbookFilter = null,  page: number = 0, size: number = 20): Observable<PaginatedAPIAddressbookContacts> {
    filters = filters || {};
    filters.page = page;
    filters.size = size;
    return this.httpClient.post<PaginatedAPIAddressbookContacts>(`${AppConfig.endpointURL}/v2/contacts/search`, filters, {
      headers: this.generateContactsAPIHeaders(companyId)
    });
  }

  public createContact(companyId: number, userId: number, contact: APIAddressbookRecord): Observable<APIAddressbookRecord> {
    return this.httpClient.post<APIAddressbookRecord>(`${AppConfig.endpointURL}/v2/contacts/`, contact, {
      headers: this.generateContactsAPIHeaders(companyId, userId)
    });
  }

  public updateContact(companyId: number, userId: number, contactId: number, contact: APIAddressbookRecord) {
    return this.httpClient.put<APIAddressbookRecord>(`${AppConfig.endpointURL}/v2/contacts/${contactId}`, contact, {
      headers: this.generateContactsAPIHeaders(companyId, userId)
    });
  }

  public deleteContact(companyId: number, userId: number, contactId: number) {
    return this.httpClient.delete(`${AppConfig.endpointURL}/v2/contacts/${contactId}`, {
      headers: this.generateContactsAPIHeaders(companyId, userId)
    });
  }

  public getUpdatePermission(companyId: number): Observable<APIAddressbookPermission[]>{ 
    return this.httpClient.get<APIAddressbookPermission[]>(`${AppConfig.endpointURL}/v1/users`, {
      headers: this.generateContactsAPIHeaders(companyId)
    });
  }

  private generateContactsAPIHeaders(companyId: number = undefined, userId: number = undefined) {
    let headers = {};
    if(companyId) {
      headers["Voverc-Company-ID"] = String(companyId);
    }
    if(userId) {
      headers["Voverc-User-ID"] = String(userId);
    }
    return headers;
  }

  public fromAPIAddressbookRecord(contact: APIAddressbookRecord): AddressbookRecord {
    let defaultNumber = contact.phones.find(p => p.label === AddressbookRecordPhoneLabel.PRIMARY);
    if(!defaultNumber && contact.phones.length > 0) defaultNumber = contact.phones[0];
    return {
      id: contact.id,
      firstName: contact.first_name,
      lastName: contact.last_name || '',
      defaultNumber: defaultNumber,
      numbers: contact.phones,
      type: ADDRESSBOOK_TYPES.EXTERNAL,
      emails: contact.emails,
      address: contact.addresses.length > 0 ? contact.addresses[0] : null,
      website: contact.website,
      job_title: contact.job_title,
      company_name: contact.company_name,
      CRM: contact.crm_name,
      crm_logo: contact.crm_logo,
      crm_contact_url: contact.crm_contact_url
    }
  }

  public importContacts(companyId: number, userId: number, payload: any): Observable<ImportResponse> {
    const headers: HttpHeaders = new HttpHeaders ({
      "Voverc-Company-ID": companyId.toString(),
      "Voverc-User-ID": userId.toString()
    });
    return this.httpClient.post<ImportResponse>(`${AppConfig.endpointURL}/v2/contacts/import`,
    payload, { headers: headers });
  }

  public confirmImport(userId: number, importId: number) {
    const headers: HttpHeaders = new HttpHeaders ({
      "Voverc-User-ID": userId.toString()
    });
    return this.httpClient.get<any>(`${AppConfig.endpointURL}/v2/contacts/import/${importId.toString()}/run`, {
      headers: headers
    });
  }

  public previewImport(userId: number, importId: number) {
    const headers: HttpHeaders = new HttpHeaders ({
      "Voverc-User-ID": userId.toString()
    });
    return this.httpClient.get<any>(`${AppConfig.endpointURL}/v2/contacts/import/${importId.toString()}/preview`, {
      headers: headers
    });
  }
}